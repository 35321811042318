import { Link } from "gatsby"
import React from "react"
import Page from "../../components/page"
import ResponsiveImageGrid from "../../components/responsive-image-grid"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"
import useScreenSize from "../../utils/useScreenSize"

const privateGrid = (labels, isSmallScreen, pagePaths) => [
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    unified: true,
    gridClass: "interiors-apartment-b-grid-container",
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: 48.5,
            src: "/images/interiors/apartment-b.jpg",
            containerClass: "img-container interiors-apartment-b-img-container",
            alt: labels.interiorsApartmentBAlt,
            imgTitle: labels.interiorsApartmentBImgTitle,
            text: labels.interiorsApartmentBText,
            textContainerClass: "interiors-apartment-b-text-container"
          },
          {
            type: "image",
            weight: 48.5,
            src: "/images/interiors/apartment-b-cocktail-collection.jpg",
            containerClass:
              "img-container interiors-apartment-b-cocktail-collection-img-container",
            alt: labels.interiorsApartmentBCocktailCollectionAlt,
            imgTitle: labels.interiorsApartmentBCocktailCollectionImgTitle
          }
        ]
      : [
          {
            type: "image",
            weight: 32.33,
            src: "/images/interiors/apartment-b.jpg",
            containerClass: "img-container interiors-apartment-b-img-container",
            alt: labels.interiorsApartmentBAlt,
            imgTitle: labels.interiorsApartmentBImgTitle,
            text: labels.interiorsApartmentBText,
            textContainerClass: "interiors-apartment-b-text-container"
          },
          {
            type: "grid",
            weight: 32.34,
            orientation: "vertical",
            unified: true,
            grid: [
              {
                type: "html",
                weight: 41,
                html: (
                  <div className="paragraph-div-centered-relatively interiors-apartment-b-text-container">
                    <p>{labels.interiorsApartmentBText}</p>
                  </div>
                )
              },
              {
                type: "image",
                weight: 48.5,
                src: "/images/interiors/apartment-b-mens-secrets.jpg",
                alt: labels.interiorsApartmentBMensSecretsAlt,
                imgTitle: labels.interiorsApartmentBMensSecretsImgTitle
              }
            ]
          },
          {
            type: "image",
            weight: 32.33,
            src: "/images/interiors/apartment-b-cocktail-collection.jpg",
            containerClass:
              "img-container interiors-apartment-b-cocktail-collection-img-container",
            alt: labels.interiorsApartmentBCocktailCollectionAlt,
            imgTitle: labels.interiorsApartmentBCocktailCollectionImgTitle
          }
        ]
  },
  {
    type: isSmallScreen ? "grid" : undefined,
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: isSmallScreen ? 100 : 0,
        src: "/images/interiors/apartment-b-mens-secrets.jpg",
        alt: labels.interiorsApartmentBMensSecretsAlt,
        imgTitle: labels.interiorsApartmentBMensSecretsImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    unified: true,
    gridClass: "interiors-apartment-p-grid-container",
    grid: [
      {
        type: "image",
        weight: isSmallScreen ? 48.5 : 49.25,
        src: "/images/interiors/apartment-p.jpg",
        containerClass: "img-container interiors-apartment-p-img-container",
        alt: labels.interiorsApartmentPAlt,
        imgTitle: labels.interiorsApartmentPImgTitle,
        text: labels.interiorsApartmentPText,
        textContainerClass: "interiors-apartment-p-text-container",
        textClass: "interiors-apartment-p-text"
      },
      {
        type: "image",
        weight: isSmallScreen ? 48.5 : 49.25,
        src: "/images/interiors/x-armchair-red-mask.jpg",
        containerClass:
          "img-container interiors-x-armchair-red-mask-img-container",
        alt: labels.interiorsApartmentPXArmchairRedAlt,
        imgTitle: labels.interiorsApartmentPXArmchairRedImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    unified: true,
    gridClass: "interiors-apartment-k-grid-container",
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: isSmallScreen ? 48.5 : 49.25,
            src: "/images/interiors/apartment-k-living-room.jpg",
            containerClass:
              "img-container interiors-apartment-k-living-room-img-container",
            alt: labels.interiorsApartmentKLivingRoomAlt,
            imgTitle: labels.interiorsApartmentKLivingRoomImgTitle,
            text: labels.interiorsApartmentKText,
            textContainerClass: "interiors-apartment-k-text-container",
            textClass: "interiors-apartment-k-text"
          },
          {
            type: "image",
            weight: isSmallScreen ? 48.5 : 49.25,
            src: "/images/interiors/apartment-k-bookshelf.jpg",
            containerClass:
              "img-container interiors-apartment-k-bookshelf-img-container",
            alt: labels.interiorsApartmentKBookshelfAlt,
            imgTitle: labels.interiorsApartmentKBookshelfImgTitle
          }
        ]
      : [
          {
            type: "image",
            weight: 32.33,
            src: "/images/interiors/apartment-k-living-room.jpg",
            containerClass:
              "img-container interiors-apartment-k-living-room-img-container",
            alt: labels.interiorsApartmentKLivingRoomAlt,
            imgTitle: labels.interiorsApartmentKLivingRoomImgTitle,
            text: labels.interiorsApartmentKText,
            textContainerClass: "interiors-apartment-k-text-container",
            textClass: "interiors-apartment-k-text"
          },
          {
            type: "image",
            weight: 32.33,
            src: "/images/interiors/apartment-k-hallway.jpg",
            containerClass:
              "img-container interiors-apartment-k-hallway-img-container",
            alt: labels.interiorsApartmentKHallwayAlt,
            imgTitle: labels.interiorsApartmentKHallwayImgTitle
          },
          {
            type: "image",
            weight: 32.33,
            src: "/images/interiors/apartment-k-bookshelf.jpg",
            containerClass:
              "img-container interiors-apartment-k-bookshelf-img-container",
            alt: labels.interiorsApartmentKBookshelfAlt,
            imgTitle: labels.interiorsApartmentKBookshelfImgTitle
          }
        ]
  },
  {
    type: isSmallScreen ? "grid" : undefined,
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: isSmallScreen ? 100 : 0,
        src: "/images/interiors/apartment-k-hallway.jpg",
        containerClass:
          "img-container interiors-apartment-k-hallway-img-container",
        alt: labels.interiorsApartmentKHallwayAlt,
        imgTitle: labels.interiorsApartmentKHallwayImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="interiors-public-and-business-other-text-container">
            <p>{labels.interiorsOtherText}:</p>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.publicAndBusinessInteriors}
            >
              <p>{labels.interiorsBusinessAndPublicOtherText}</p>
            </Link>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.gameOfLightsInteriors}
            >
              <p>{labels.interiorsGameOfLightsOtherText}</p>
            </Link>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.backRoomInteriors}
            >
              <p>{labels.interiorsBackRoomOtherText}</p>
            </Link>
          </div>
        )
      }
    ]
  }
]

export default function Private({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.privateInteriors}
      description={labels.privateInteriorsDescription}
      keywords={labels.privateInteriorsKeywords}
      ogUrl={paths(labels).privateInteriors}
      ogImage="/images/og-images/private-interiors.jpg"
      headerPadding={isSmallScreen}
    >
      <ResponsiveImageGrid
        grid={privateGrid(labels, isSmallScreen, paths(labels))}
        margin={false}
      />
    </Page>
  )
}
